import { Agreement, AgreementBalance, AgreementBalanceSummary } from '@common/types/agreement';
import { UserLimit } from '@common/types/user-limit';
import { createSlice } from '@reduxjs/toolkit';

import {
  getActiveAgreement,
  getAgreementBalance,
  getAgreementBalanceSummary,
  getUserLimit,
} from '../thunks/banking';

export interface BankingInitiaState {
  loading: boolean;
  error: boolean;
  userLimit: {
    data: UserLimit | null;
    loading: boolean;
    error: boolean;
  };
  activeAgreement: Agreement | null;
  agreementBalanceSummary: {
    data: AgreementBalanceSummary | null;
    loading: boolean;
    error: boolean;
  };
  agreementBalance: {
    data: AgreementBalance | null;
    loading: boolean;
    error: boolean;
  };
}

export const initialState: BankingInitiaState = {
  loading: false,
  error: false,
  userLimit: {
    data: null,
    loading: false,
    error: false,
  },
  activeAgreement: null,
  agreementBalanceSummary: {
    data: null,
    loading: false,
    error: false,
  },
  agreementBalance: {
    data: null,
    loading: false,
    error: false,
  },
};

const bankingSlice = createSlice({
  name: 'banking',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
  // get agreement
  extraReducers: (builder) => {
    builder.addCase(getActiveAgreement.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(getActiveAgreement.fulfilled, (state, action) => ({
      ...state,
      activeAgreement: action.payload,
      loading: false,
      error: false,
    }));

    builder.addCase(getActiveAgreement.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));
    // get user limit
    builder.addCase(getUserLimit.pending, (state) => ({
      ...state,
      userLimit: {
        ...state.userLimit,
        loading: true,
        error: false,
      },
    }));
    builder.addCase(getUserLimit.fulfilled, (state, action) => ({
      ...state,
      userLimit: {
        data: action.payload,
        loading: false,
        error: false,
      },
    }));
    builder.addCase(getUserLimit.rejected, (state) => ({
      ...state,
      userLimit: {
        ...state.userLimit,
        loading: false,
        error: true,
      },
    }));
    // get agreement balance
    builder.addCase(getAgreementBalance.pending, (state) => ({
      ...state,
      agreementBalance: {
        ...state.agreementBalance,
        loading: true,
        error: false,
      },
    }));
    builder.addCase(getAgreementBalance.fulfilled, (state, action) => ({
      ...state,
      agreementBalance: {
        data: action.payload,
        loading: false,
        error: false,
      },
    }));
    builder.addCase(getAgreementBalance.rejected, (state) => ({
      ...state,
      agreementBalance: {
        ...state.agreementBalance,
        loading: false,
        error: true,
      },
    }));
    // get agreement balance summary
    builder.addCase(getAgreementBalanceSummary.pending, (state) => ({
      ...state,
      agreementBalanceSummary: {
        ...state.agreementBalanceSummary,
        loading: true,
        error: false,
      },
    }));
    builder.addCase(getAgreementBalanceSummary.fulfilled, (state, action) => ({
      ...state,
      agreementBalanceSummary: {
        data: action.payload,
        loading: false,
        error: false,
      },
    }));
    builder.addCase(getAgreementBalanceSummary.rejected, (state) => ({
      ...state,
      agreementBalanceSummary: {
        ...state.agreementBalanceSummary,
        loading: false,
        error: true,
      },
    }));
  },
});

export const { reset, setLoading } = bankingSlice.actions;

export default bankingSlice.reducer;
