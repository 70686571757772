import { useMemo, useState } from 'react';
import CurrencyInputField from 'react-currency-input-field';

import InputLabel from '@common/components/input-label/InputLabel';
import { WidgetProps } from '@rjsf/utils';
import classNames from 'classnames';

interface CurrencyWidgetProps extends WidgetProps {
  limit?: number;
  hideError?: boolean;
  externalHandler?: () => void;
  customClassName?: string;
  suffix?: string;
}

const CurrencyWidget = ({
  disabled,
  value,
  onChange,
  label,
  limit,
  customClassName,
  suffix = ' €',
  ...props
}: CurrencyWidgetProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const className = useMemo(() => {
    return (
      customClassName ??
      classNames(
        label ? 'pt-3' : '',
        'h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold placeholder:font-normal placeholder:text-gray-400 focus:outline-none'
      )
    );
  }, [customClassName, label]);
  const hasValue = props.value !== undefined || value !== undefined;

  return (
    <div className="relative overflow-hidden">
      <CurrencyInputField
        {...props}
        allowNegativeValue={false}
        allowDecimals={false}
        decimalsLimit={0}
        autoComplete="off"
        disableGroupSeparators={false}
        suffix={suffix}
        disabled={disabled}
        defaultValue={value}
        className={className}
        max={limit}
        value={value}
        onValueChange={(e) => {
          if (limit && Number(e) > limit) {
            onChange(limit);
          } else {
            onChange(e);
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          props.onBlur(props.id, value);
        }}
        placeholder={label ? '' : (props.placeholder ?? '')}
      />

      {!!label && <InputLabel text={label} shouldMinimize={isFocused || hasValue} />}
    </div>
  );
};

export default CurrencyWidget;
