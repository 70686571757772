import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import PencilIcon from '@common/components/icons/PencilIcon';
import Slider from '@common/components/slider/Slider';
import { Currency } from '@common/constants';
import { SubscriptionName } from '@common/enums';
import useDebouncedValue from '@common/hooks/useDebouncedValue';
import useIntercomChat from '@common/hooks/useIntercomChat';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIN_WITHDRAWAL_AMOUNT } from '@monefit-es/constants/withdrawal-limit';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import { PaymentStep } from '@monefit-es/pages/payment-page/PaymentPage.types';
import clsx from 'clsx';
import * as yup from 'yup';

import InfoRow from '../info-row/InfoRow';
import PaymentStartFormHeader from '../payment-start-form-header/PaymentStartFormHeader';
import { getClassNames } from './PaymentStartForm.classes';
import m from './PaymentStartForm.messages';

interface FormValues {
  amount: number;
}
interface PaymentStartFormProps {
  setStep: (v: PaymentStep) => void;
  setAmount: (v: number) => void;
  amount?: number;
}

const PaymentStartForm = ({ setStep, setAmount, amount: defaultAmount }: PaymentStartFormProps) => {
  const [isManualAmountEdit, setIsManualAmountEdit] = useState(false);
  const [pendingLeftToPayRequests, setPendingLeftToPayRequests] = useState(0);
  const { getHasActiveSubsctiption } = useSubsciptions();
  const { formatMessage, formatNumber } = useIntl();
  const {
    balanceData,
    balanceLoading,
    limitLoading,
    agreement,
    balanceSummaryLoading,
    balanceSummary,
    fetchBalanceSummary,
  } = useLimitAndBalance({
    disableAgreementFetch: true,
    disableBalanceFetch: true,
    disableLimitFetch: true,
  });

  const paymentData = balanceSummary?.balance?.total;
  const { openChat } = useIntercomChat();
  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .required()
        .max(paymentData?.maxPayment ?? 0)
        .min(paymentData?.minPayment ?? 0),
    });
  }, [paymentData?.maxPayment, paymentData?.minPayment]);

  const minAmount = useMemo(
    () => paymentData?.minPayment ?? MIN_WITHDRAWAL_AMOUNT,
    [paymentData?.minPayment]
  );
  const hasSubscription = getHasActiveSubsctiption(SubscriptionName.CREDIT_LINE_PREMIUM);

  // TODO: Probably this usedCredit be deleted and paymentData.maxPayment used instead. Should be checked with Edli
  // if used credit won't be used then we can avoid extra request in PaymentPage as balanceData is fetched only for this variable
  const usedCredit = useMemo(
    () =>
      hasSubscription
        ? balanceData?.totalOutstandingAmountExcludingInterest
        : balanceData?.totalOutstandingAmount,

    [
      hasSubscription,
      balanceData?.totalOutstandingAmount,
      balanceData?.totalOutstandingAmountExcludingInterest,
    ]
  );

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: defaultAmount || paymentData?.maxPayment || usedCredit || MIN_WITHDRAWAL_AMOUNT,
    },
  });

  const {
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = ctx;

  const amount = watch('amount');
  const debouncedAmount = useDebouncedValue(amount, isManualAmountEdit ? 1000 : 100);
  const abortControllerRef = useRef(new AbortController());

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      setAmount(v.amount);
      setStep(PaymentStep.CHECKOUT);
    },
    [setAmount, setStep]
  );

  const handleFetchLeftToPay = useCallback(async () => {
    setPendingLeftToPayRequests((prev) => prev + 1);
    try {
      if (debouncedAmount < minAmount) {
        setValue('amount', minAmount);
      }
      // We need to abort previous requests if new one was created via slider/currencyInput
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const amountForFetch = debouncedAmount < minAmount ? minAmount : debouncedAmount;

      await fetchBalanceSummary(agreement?.id, amountForFetch, abortControllerRef.current.signal);
    } catch (_) {
    } finally {
      setPendingLeftToPayRequests((prev) => prev - 1);
    }
  }, [agreement?.id, debouncedAmount, minAmount, usedCredit, setValue]);

  const toggleEditMode = useCallback(() => {
    setIsManualAmountEdit((prev) => !prev);
  }, []);

  const classes = useMemo(() => getClassNames(), []);

  useEffect(() => {
    if (!usedCredit) {
      return;
    }
    handleFetchLeftToPay();
  }, [usedCredit, handleFetchLeftToPay]);

  useEffect(() => {
    if (amount === minAmount || amount === paymentData?.maxPayment) {
      return;
    }
    setValue('amount', Number(Math.floor(amount).toFixed(2)));
  }, [amount, minAmount, setValue, paymentData?.maxPayment]);

  return (
    <form
      autoComplete="off"
      className="flex size-full"
      onSubmit={ctx.handleSubmit(async (v: FormValues) => {
        try {
          await handleFormSubmit(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="flex w-full">
          <div className="flex w-full flex-col items-start justify-end self-stretch">
            <PaymentStartFormHeader />
            <div className={classes.formContainer}>
              <div className="flex flex-col items-start justify-center gap-5 self-stretch">
                <div className="flex flex-col items-start justify-center gap-2 self-stretch">
                  <div className="text-base leading-snug tracking-tight text-black">
                    {formatMessage(m.amountSelect)}
                  </div>
                  {usedCredit && isManualAmountEdit && (
                    <CurrencyInput
                      disabled={isSubmitting}
                      name="amount"
                      customClassName="h-14"
                      limit={paymentData?.maxPayment ?? usedCredit}
                    />
                  )}
                  {!isManualAmountEdit && (
                    <div className="flex justify-between self-stretch">
                      <div
                        className={clsx('font-heavy  text-4xl leading-10 text-black sm:text-5xl')}
                      >
                        {formatNumber(amount, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          currencyDisplay: 'symbol',
                          currency: Currency.EUR,
                          style: 'currency',
                        })}
                      </div>
                      <button type="button" onClick={toggleEditMode}>
                        <PencilIcon />
                      </button>
                    </div>
                  )}
                </div>
                {usedCredit && !isManualAmountEdit && (
                  <Slider
                    color="green"
                    allowsDecimals
                    name="amount"
                    min={minAmount}
                    step={1}
                    max={paymentData?.maxPayment ?? usedCredit}
                  />
                )}

                <InfoRow
                  title={formatMessage(m.leftToPayAfterThisPayment)}
                  value={
                    paymentData?.leftToPay !== undefined && amount >= minAmount
                      ? formatNumber(paymentData.leftToPay, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          currencyDisplay: 'symbol',
                          currency: Currency.EUR,
                          style: 'currency',
                        })
                      : ''
                  }
                  loading={pendingLeftToPayRequests > 0}
                />
                {amount === minAmount && (
                  <InfoRow
                    title={formatMessage(m.payLesss)}
                    value={
                      <div className="font-semibold">
                        <Button
                          spacing={ButtonSpacing.XS}
                          type="button"
                          color="dark-gray"
                          text="Contact support"
                          textSize="sm"
                          onClick={() => openChat()}
                        />
                      </div>
                    }
                  />
                )}
                <Button
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    limitLoading ||
                    balanceLoading ||
                    balanceSummaryLoading
                  }
                  type="submit"
                  fullWidth
                  text={formatMessage(m.continue)}
                />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default PaymentStartForm;
