import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import ImageCard from '@common/components/image-card/ImageCard';
import SelectInput from '@common/components/select-input/SelectInput';
import Slider from '@common/components/slider/Slider';
import SpinnerLoader from '@common/components/spinner-loader/SpinnerLoader';
import { useApplicationFlowInstance } from '@common/hooks';
import useDebouncedValue from '@common/hooks/useDebouncedValue';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, WithdrawalFetch } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { yupResolver } from '@hookform/resolvers/yup';
import IMAGE_SRC from '@src/assets/img/monefit-ee/approved-img.webp';
import * as yup from 'yup';

const MIN_AMOUNT = 50;
const STEP = 10;

interface FormValues {
  amount: number;
  bankAccount: string;
}

const ApprovedForm = () => {
  const { trackEvent } = useTracker({});
  const intl = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();

  const { loading, data } = useSelector((st) => st.application.flowInstance);
  const typedResponseData = data?.responseData as WithdrawalFetch;

  const {
    limit,
    bankAccounts,
    firstRepaymentAmount,
    amount: customerSelectedAmount,
  } = typedResponseData;

  const initialAmountValue = useMemo(
    () => Math.round(Math.min(customerSelectedAmount, Math.max(0.75 * limit)) / STEP) * STEP,
    [limit, customerSelectedAmount]
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!values.amount) {
        return;
      }
      dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: { amount: values.amount, bankAccount: values.bankAccount },
        })
      )
        .unwrap()
        .then(() => trackEvent(TrackEvent.APPROVED_PAGE_SUBMIT));
    },
    [dispatch, instanceId, trackEvent]
  );
  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        amount: yup
          .number()
          .typeError(
            intl.formatMessage({
              defaultMessage: 'The field is mandatory',
              description: 'Withdrawal: "The field is mandatory (amount field)"',
            })
          )
          .min(1)
          .max(limit)
          .required(
            intl.formatMessage({
              defaultMessage: 'The field is mandatory',
              description: 'Withdrawal: "The field is mandatory (amount field)"',
            })
          ),
        bankAccount: yup.string().required(
          intl.formatMessage({
            defaultMessage: 'The field is mandatory',
            description: 'Withdrawal: "The field is mandatory (bank account field)"',
          })
        ),
      }),
    [intl, limit]
  );

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: initialAmountValue,
      bankAccount: bankAccounts?.[0] ?? undefined,
    },
  });

  const {
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = ctx;

  const amount = watch('amount');
  const bankAccount = watch('bankAccount');
  const debouncedAmount = useDebouncedValue(amount, 1000);

  const requestFirstlyMonthPayment = useCallback(async () => {
    if (debouncedAmount > limit || debouncedAmount < MIN_AMOUNT) {
      return;
    }
    dispatch(setShowLoader(false));
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.FETCH,
        data: { amount: debouncedAmount },
        instanceId,
      })
    )
      .unwrap()
      .finally(() => dispatch(setShowLoader(true)));
  }, [dispatch, debouncedAmount, instanceId, limit]);

  useEffect(() => {
    requestFirstlyMonthPayment();
  }, [requestFirstlyMonthPayment]);

  const correctAmountValue = useCallback(() => {
    if (debouncedAmount > limit) {
      setValue('amount', limit);
    }

    if (debouncedAmount < MIN_AMOUNT) {
      setValue('amount', MIN_AMOUNT);
    }

    trackEvent(TrackEvent.APPROVED_PAGE_AMOUNT_SELECTED);
  }, [setValue, limit, debouncedAmount, trackEvent]);

  const amountToDisplay = useMemo(() => {
    if (debouncedAmount > limit) {
      return limit;
    }
    if (debouncedAmount < MIN_AMOUNT) {
      return MIN_AMOUNT;
    }

    return debouncedAmount;
  }, [debouncedAmount, limit]);

  return (
    <ImageCard
      imageSrc={IMAGE_SRC}
      hasSmallerImageText
      imageText={intl.formatMessage({
        defaultMessage: 'APPROVED',
        description: 'Withdrawal: "Approved", (Image text)',
      })}
      imageSubText={intl.formatMessage({ defaultMessage: 'SUBJECT TO FINAL CHECKS' })}
    >
      <form
        className="flex size-full"
        onSubmit={ctx.handleSubmit(async (v: FormValues) => {
          try {
            await handleSubmit(v);
          } catch (_) {}
        })}
      >
        <FormProvider {...ctx}>
          <div className="flex size-full flex-col gap-6 sm:gap-2.5">
            <div className="flex flex-col items-start justify-start gap-5 self-stretch">
              <div className="self-stretch text-lg font-semibold leading-tight tracking-tight text-sky-600">
                <FormattedMessage
                  defaultMessage="Approved limit subject to final checks."
                  description="Withdrawal: 'Approved limit subject to final checks.'"
                />
              </div>
              <div className="text-5xl font-black leading-[48px] text-black">
                <FormattedNumber
                  style="currency"
                  currency="EUR"
                  value={limit}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </div>
            </div>
            <div className="flex h-full flex-col items-center justify-between gap-6 self-stretch sm:gap-2.5">
              <div className="flex  flex-col items-center justify-center gap-5 self-stretch">
                <div className="inline-flex items-center justify-between self-stretch">
                  <div className="font-medium leading-normal text-black">
                    <FormattedMessage
                      defaultMessage="What amount do you want to request at the moment?"
                      description="Withdrawal: 'What amount do you want to request at the moment?'"
                    />
                  </div>
                  <div className="min-w-[90px] text-right text-lg font-black leading-normal text-black">
                    <FormattedNumber
                      currency="EUR"
                      style="currency"
                      value={amountToDisplay}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <CurrencyInput
                  limit={limit}
                  hideError={true}
                  label={intl.formatMessage({
                    defaultMessage: 'Amount to withdraw',
                    description: 'Withdrawal: "Amount to withdraw"',
                  })}
                  name="amount"
                />
                <Slider
                  name="amount"
                  max={limit}
                  min={MIN_AMOUNT}
                  step={STEP}
                  externalHandler={correctAmountValue}
                />
              </div>
              <div className="inline-flex w-full flex-col items-center justify-start gap-2.5">
                <div className="inline-flex items-start justify-start gap-2.5">
                  <div className="text-[14px] font-normal text-black">
                    <FormattedMessage
                      defaultMessage="First min monthly payment:"
                      description="Withdrawal: 'First min monthly payment:'"
                    />
                  </div>

                  <div className="w-[70px] text-[14px] font-semibold text-black">
                    {!!loading ? (
                      <SpinnerLoader />
                    ) : (
                      <FormattedNumber
                        style="currency"
                        currency="EUR"
                        value={firstRepaymentAmount ?? 0}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <SelectInput
                    label={intl.formatMessage({ defaultMessage: 'Bank account' })}
                    name="bankAccount"
                    options={bankAccounts?.map((x) => ({ label: x, value: x }))}
                  />
                </div>
                <div className="inline-flex items-start justify-start gap-2.5">
                  <div className="text-[14px] font-normal text-black">
                    <FormattedMessage
                      defaultMessage="Your bank account:"
                      description="Withdrawal: 'Your bank account:'"
                    />
                  </div>
                  {bankAccount && (
                    <div className="flex items-start justify-start gap-[5px]">
                      <div className="text-[14px] font-semibold text-black">
                        <span>
                          <FormattedMessage
                            defaultMessage="IBAN:"
                            description="Withdrawal: 'IBAN'"
                          />{' '}
                          {bankAccount}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Button
                type="submit"
                extraClassNames="mt-6 sm:mt-0"
                disabled={isSubmitting || !isValid || loading}
                text={intl.formatMessage({
                  defaultMessage: 'Continue',
                  description: 'Continue button text',
                })}
                fullWidth
              />
            </div>
          </div>
        </FormProvider>
      </form>
    </ImageCard>
  );
};

export default ApprovedForm;
