import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Tooltip } from 'react-tooltip';

import Button from '@common/components/button/Button';
import TextInput from '@common/components/text-input/TextInput';
import { useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectUserData } from '@common/redux/selectors/user';
import { fetchUser } from '@common/redux/thunks/user';

import AccountCard from '../account-card/AccountCard';
import AccountIdField from '../account-id-field/AccountIdField';
import m from './AccountPersonalDetails.messages';

const AccountPersonalSettings = () => {
  const dispatch = useDispatch<RootDispatch>();
  const userData = useSelector(selectUserData);
  const { formatMessage } = useIntl();
  const ctx = useForm({
    mode: 'onBlur',
    // resolver: yupResolver(schema),
  });

  // TODO: implementation and types. For now form is static and disabled
  const handleFormSubmit = useCallback(async (v: any) => {}, []);

  const fetchUserData = useCallback(async () => {
    if (userData) {
      return;
    }
    await dispatch(fetchUser()).unwrap();
  }, [dispatch, userData]);

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <AccountCard>
      {userData && (
        <form
          autoComplete="off"
          className="flex size-full flex-col gap-6"
          onSubmit={ctx.handleSubmit(async (v: any) => {
            try {
              await handleFormSubmit(v);
            } catch (_) {}
          })}
        >
          <FormProvider {...ctx}>
            <div
              className="flex w-full flex-col gap-3"
              data-tooltip-id="personal-details-tooltip"
              data-tooltip-content={formatMessage(m.tooltipText)}
            >
              <TextInput
                styling="account"
                name="firstName"
                disabled
                defaultValue={userData?.firstName}
                label={formatMessage(m.firstNameLabel)}
              />
              <TextInput
                styling="account"
                name="lastName"
                disabled
                defaultValue={userData?.lastName}
                label={formatMessage(m.lastNameLabel)}
              />
              <AccountIdField value={userData?.id} label={formatMessage(m.accountIdLabel)} />
              <TextInput
                styling="account"
                name="personalCode"
                disabled
                defaultValue={userData?.personalCode}
                label={formatMessage(m.idNumberLabel)}
              />

              <TextInput
                styling="account"
                name="address"
                disabled
                defaultValue={userData?.activeAddress?.fullAddress}
                label={formatMessage(m.addressLabel)}
              />
              <TextInput
                styling="account"
                name="tax"
                disabled
                defaultValue={userData?.region}
                label={formatMessage(m.taxResidencyLabel)}
              />
            </div>
            <Button text={formatMessage(m.btnText)} />
          </FormProvider>
          <Tooltip id="personal-details-tooltip" />
        </form>
      )}
    </AccountCard>
  );
};

export default AccountPersonalSettings;
