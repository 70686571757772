import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import { Currency } from '@common/constants';
import { SubscriptionName } from '@common/enums';
import useSubsciptions from '@common/hooks/useSubscriptions';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import clsx from 'clsx';
import { add, endOfDay, format, isBefore } from 'date-fns';

import InfoRow from '../info-row/InfoRow';
import { getClassNames } from '../payment-start-form/PaymentStartForm.classes';
import m from '../payment-start-form/PaymentStartForm.messages';

const PaymentStartFormHeader = () => {
  const { getHasActiveSubsctiption } = useSubsciptions();
  const { formatMessage, formatNumber } = useIntl();
  const { balanceData, balanceLoading, limitLoading, balanceSummary } = useLimitAndBalance({
    disableAgreementFetch: true,
    disableBalanceFetch: true,
    disableLimitFetch: true,
  });
  const hasSubscription = getHasActiveSubsctiption(SubscriptionName.CREDIT_LINE_PREMIUM);
  const usedCredit = useMemo(
    () =>
      hasSubscription
        ? balanceData?.totalOutstandingAmountExcludingInterest
        : balanceData?.totalOutstandingAmount,

    [
      hasSubscription,
      balanceData?.totalOutstandingAmount,
      balanceData?.totalOutstandingAmountExcludingInterest,
    ]
  );

  const invoiceData = balanceSummary?.invoice?.lastInvoiceByDueDate;

  const invoiceDueDate = useMemo(() => {
    const dateFormat = 'dd.MM.yyyy';
    if (balanceSummary?.invoice?.lastInvoiceByDueDate?.dueDate) {
      return format(new Date(balanceSummary?.invoice?.lastInvoiceByDueDate?.dueDate), dateFormat);
    }

    return null;
  }, [balanceSummary?.invoice?.lastInvoiceByDueDate?.dueDate]);

  const isInvoiceOverdue = useMemo(() => {
    const dueDate = balanceSummary?.invoice?.lastInvoiceByDueDate?.dueDate ?? null;
    if (!dueDate) {
      return false;
    }

    const today = endOfDay(new Date());
    return isBefore(endOfDay(dueDate), today);
  }, [balanceSummary?.invoice?.lastInvoiceByDueDate?.dueDate]);

  const invoicePeriod = useMemo(() => {
    if (!invoiceData?.periodEndDate || !invoiceData?.periodStartDate) {
      return null;
    }
    const dateFormat = 'dd MMM';
    return `${format(new Date(invoiceData.periodStartDate), dateFormat)} - ${format(new Date(invoiceData.periodEndDate), dateFormat)}`;
  }, [invoiceData]);

  const classes = useMemo(() => getClassNames(), []);
  //show invoice from 1-15 and hide from 16 - EOM
  const showInvoiceDate = 30 - new Date().getDate() >= 15;

  return (
    <div className={classes.infoCardContainer}>
      <InfoRow
        title={formatMessage(m.usedCredit)}
        value={
          usedCredit
            ? formatNumber(usedCredit, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                currencyDisplay: 'symbol',
                currency: Currency.EUR,
                style: 'currency',
              })
            : '-'
        }
        loading={limitLoading || limitLoading}
      />
      {!!invoicePeriod && (
        <InfoRow title={formatMessage(m.invoicePeriod)} value={invoicePeriod} loading={false} />
      )}

      {invoiceDueDate && (
        <InfoRow
          title={formatMessage(m.due)}
          value={<div className={clsx({ 'text-red-500': isInvoiceOverdue })}>{invoiceDueDate}</div>}
          loading={balanceLoading}
        />
      )}

      {showInvoiceDate && !!invoiceData?.documentLink && (
        <InfoRow
          title={formatMessage(m.invoice)}
          value={
            <div className="font-semibold">
              <a href={invoiceData.documentLink} target="_blank" rel="noopener noreferrer">
                <Button
                  text={formatMessage(m.download)}
                  spacing={ButtonSpacing.XS}
                  color="white"
                  hoverClassName="hover:bg-black hover:text-white"
                  borderClassName="border-1 border-white"
                  textSize="sm"
                  type="button"
                />
              </a>
            </div>
          }
          loading={false}
        />
      )}
    </div>
  );
};

export default PaymentStartFormHeader;
