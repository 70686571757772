import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { useLocale } from '@common/hooks';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import clsx from 'clsx';

import DashboardInvoiceInformation from '../dashboard-invoice-information/DashboardInvoiceInformation';
import { getClassNames } from '../dashboard/Dashboard.classes';
import m from '../dashboard/Dashboard.messages';

interface DashboardInvoiceSectionProps {
  hasSubscription: boolean;
}

const DashboardInvoiceSection: FC<DashboardInvoiceSectionProps> = ({ hasSubscription }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();

  const { limitData, balanceData, limitLoading, balanceError, balanceLoading } = useLimitAndBalance(
    { disableAgreementFetch: true, disableLimitFetch: true }
  );

  const noBalanceData = useMemo(
    () => !balanceLoading && balanceError,
    [balanceError, balanceLoading]
  );

  const noInvoice = useMemo(() => limitData?.balance === 0, [limitData?.balance]);
  const usedCredit = useMemo(
    () =>
      hasSubscription
        ? (balanceData?.totalOutstandingAmountExcludingInterest ?? 0)
        : (balanceData?.totalOutstandingAmount ?? 0),

    [
      hasSubscription,
      balanceData?.totalOutstandingAmount,
      balanceData?.totalOutstandingAmountExcludingInterest,
    ]
  );

  const disableActionButtons = useMemo(
    () => noInvoice || balanceLoading || limitLoading || noBalanceData || usedCredit === 0,
    [noInvoice, balanceLoading, limitLoading, noBalanceData, usedCredit]
  );

  const classNames = useMemo(() => getClassNames(), []);

  const handleStartPayment = useCallback(() => {
    navigate(`/${localeWithCountry}/payment`);
  }, [navigate, localeWithCountry]);

  return (
    <div className={clsx('bg-[#E7E6E1]', classNames.card)}>
      <div className="flex flex-col items-start justify-start gap-3 self-stretch">
        <div className="inline-flex items-start justify-between gap-2 self-stretch">
          {balanceLoading || limitLoading ? (
            <BlackDotsLoader style={{ height: 60 }} />
          ) : (
            <DashboardInvoiceInformation
              disableDetailsButton={disableActionButtons}
              hasSubscription={hasSubscription}
            />
          )}
        </div>
      </div>
      <Button
        onClick={handleStartPayment}
        disabled={disableActionButtons}
        className="w-full"
        buttonNode={
          <div
            className={clsx(
              'border-transparent bg-white px-3 py-4 text-xl text-black',
              classNames.button,
              {
                [classNames.invoiceButtonsHover]: !disableActionButtons,
                [classNames.disabledInvoiceButtonText]: disableActionButtons,
              }
            )}
          >
            {formatMessage(m.paymentOptionsBtnText)}
          </div>
        }
      />
    </div>
  );
};

export default DashboardInvoiceSection;
