import { useIntl } from 'react-intl';

import Layout from '@common/components/layout/Layout';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import { FlowActions } from '@common/services/application';
import DrawdownStarForm from '@monefit-es/components/drawdown-start-form/DrawdownStartForm';
import m from '@monefit-es/components/drawdown-start-form/DrawdownStartForm.messages';

const AmountPage = () => {
  const { formatMessage } = useIntl();
  useInitialFlowActionPerform({
    flowAction: FlowActions.BALANCE,
  });

  return (
    <Layout.WithNavigation
      hideProgressBar
      backButtonText={formatMessage(m.headerText)}
      maxWDesktop={545}
    >
      <DrawdownStarForm />
    </Layout.WithNavigation>
  );
};

export default AmountPage;
