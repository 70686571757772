import { bankingService } from '@common/services/banking';
import {
  Agreement,
  AgreementBalance,
  AgreementBalanceSummary,
  AgreementOriginator,
  AgreementsGetResponse,
} from '@common/types/agreement';
import { UserLimit } from '@common/types/user-limit';
import { ApiError, getUserData } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { store } from '..';

interface GetActiveAgreementRequest {
  originaror: AgreementOriginator;
  type?: 'CreditLine'; // TODO: More types
}

interface GetAgreementBalanceSummaryRequest {
  agreementId: string;
  amount?: number;
  signal?: AbortSignal;
}

export const getActiveAgreement = createAsyncThunk<Agreement | null, GetActiveAgreementRequest>(
  'banking/active-agreement',
  async (params, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }

    try {
      const searchParams = new URLSearchParams({
        'filter[originatorIdentifier][eq]': params.originaror,
        'filter[userId][eq]': user.id,
        'filter[type]': params.type ?? 'CreditLine',
        'filter[status][eq]': 'Active',
      });
      const { data } = await bankingService.get<AgreementsGetResponse>(
        `/agreement?${searchParams.toString()}`
      );

      const agreement = data.data?.[0] ?? null;
      return agreement;
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);

export const getUserLimit = createAsyncThunk<UserLimit | null, string>(
  'banking/user-limit',
  async (agreementId, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }

    try {
      const { data } = await bankingService.get<UserLimit>(
        `user-limit/agreement/${agreementId}/balance?type=credit`
      );
      return data ?? null;
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);

export const getAgreementBalance = createAsyncThunk<AgreementBalance | null, string>(
  'banking/agreement-balance',
  async (agreementId, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }

    try {
      const { data } = await bankingService.get<AgreementBalance>(
        `agreement/${agreementId}/info/balance`
      );
      return data ?? null;
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);

const balanceSummaryMap: Record<number, AgreementBalanceSummary> = {};
export const getAgreementBalanceSummary = createAsyncThunk<
  AgreementBalanceSummary | null,
  GetAgreementBalanceSummaryRequest
>(
  'banking/agreement-balance-summary',
  async ({ agreementId, amount, signal }, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }
    amount = amount ?? 0;
    if (balanceSummaryMap[amount]) {
      return balanceSummaryMap[amount];
    }
    try {
      const response = await bankingService.get<AgreementBalanceSummary>(
        `agreement/${agreementId}/info/balance-summary`,
        {
          params: { amount: amount },
          signal,
        }
      );
      const data = response.data ?? null;
      if (data) {
        balanceSummaryMap[amount] = data;
      }

      return data;
    } catch (e) {
      if (signal && signal.aborted) {
        return store.getState().banking?.agreementBalanceSummary?.data; // Set initial value if request cancelled
      }
      throw new ApiError(String(e));
    }
  }
);
