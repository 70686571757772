import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SubscriptionName } from '@common/enums';
import { useDispatch } from '@common/hooks';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { RootDispatch } from '@common/redux';
import { selectFlowInstanceLoading } from '@common/redux/selectors';
import { selectActiveAgreement, selectMainLoading } from '@common/redux/selectors/banking';
import { reset } from '@common/redux/slices/application';
import { getActiveAgreement } from '@common/redux/thunks/banking';
import { AgreementOriginator } from '@common/types/agreement';
import Dashboard from '@monefit-es/components/dashboard/Dashboard';
import MainLayout from '@monefit-es/components/layout/MainLayout';

const DashboardPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const { fetchUserSubscriptions } = useSubsciptions();
  const agreementLoading = useSelector(selectMainLoading);
  const flowInstanceLoading = useSelector(selectFlowInstanceLoading);
  const activeAgreement = useSelector(selectActiveAgreement);

  const fetchAgreement = useCallback(async () => {
    if (activeAgreement || agreementLoading) {
      return;
    }
    await dispatch(getActiveAgreement({ originaror: AgreementOriginator.MONEFIT_ES }))
      .unwrap()
      .catch(() => {});
  }, [dispatch, activeAgreement, agreementLoading]);

  useEffect(() => {
    fetchAgreement();
  }, []);

  useEffect(() => {
    fetchUserSubscriptions();
  }, []);

  useEffect(() => {
    // reset application state
    dispatch(reset());
  }, []);

  return (
    <MainLayout
      type={'dashboard'}
      hideProgressBar
      hideHeaderAndFooter={agreementLoading || flowInstanceLoading}
    >
      <Dashboard />
    </MainLayout>
  );
};

export default DashboardPage;
