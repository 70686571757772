import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApplicationFlowInstance, useDispatch, useInterval, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectFlowInstanceLoading, selectFlowInstanceStep } from '@common/redux/selectors';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';
import MonthioCard from '@monefit-es/components/bank-statement-card/BankStatementCard';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';

const BankPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const apiLoading = useSelector(selectFlowInstanceLoading);
  const currentStep = useSelector(selectFlowInstanceStep);
  const { data } = useSelector((st) => st.application.flowInstance);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  const isBankStatementSent = useMemo(
    () => data?.responseData?.isBankStatementSent || !!userId,
    [userId, data?.responseData?.isBankStatementSent]
  );

  const renderView = useCallback(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return null;
    }

    if (isBankStatementSent) {
      return <MainLoader />;
    }

    return <MonthioCard setRedirectUrl={setRedirectUrl} />;
  }, [redirectUrl, isBankStatementSent]);

  const fetchBank = useCallback(async () => {
    if (!data?.isResumed) {
      return;
    }

    await dispatch(
      performApplicationFlowAction({ action: FlowActions.FETCH, instanceId })
    ).unwrap();
  }, [data?.isResumed, dispatch, instanceId]);

  const checkBank = useCallback(async () => {
    if (apiLoading || !isBankStatementSent) {
      return;
    }
    dispatch(setShowLoader(false));
    await dispatch(performApplicationFlowAction({ action: FlowActions.CHECK, instanceId }))
      .unwrap()
      .then((res) => {
        if (res.responseData?.currentSubStepId !== FlowSteps.BANK) {
          dispatch(setShowLoader(true));
        }
      })
      .catch(() => {
        dispatch(setShowLoader(true));
      });
  }, [dispatch, instanceId, apiLoading, isBankStatementSent]);

  useInterval(checkBank, currentStep === FlowSteps.BANK ? 3000 : null);

  useEffect(() => {
    fetchBank();
  }, []);

  return (
    <MainLayout
      hideHeaderAndFooter={!!redirectUrl || isBankStatementSent}
      hideProgressBar={!!redirectUrl || isBankStatementSent}
    >
      {renderView()}
    </MainLayout>
  );
};

export default BankPage;
