export enum LocalStorageKeys {
  APPLICATION_FLOW_INSTANCE_ID = 'applicationFlowInstanceId', // Application flow instance id
  TIMER_REMAINING = 'timerRemaining',
  LOCALE = 'locale',
  LOCALES = 'locales',
  FINANCES_FORM_DATA = 'financesFormData',
  BANK_FLOW_SUBSTEP = 'bankFlowSubstep',
  USER_PREFILLED_DATA = 'prefilledData',
  IDENTIFICATION = 'identification',
  AMPLITUDE = 'amplitude',
  FLOW_ID_OR_TYPE = 'flowIdOrType',
  USER = 'user',
  WELCOME_SHOWN = 'welcomeShown',
  DOCUMENTS = 'documents',
}
