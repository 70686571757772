import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import EyeIcon from '@common/components/icons/EyeIcon';
import PencilDrawlIcon from '@common/components/icons/PencilDrawIcon';
import ImageCard from '@common/components/image-card/ImageCard';
import { useApplicationFlowInstance, useDispatch, useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getPublicUrl, getUserData } from '@common/utils';
import { useDocuments } from '@monefit-es/hooks/useDocuments';
import documentsImg from '@src/assets/img/monefit-es/documents_1.webp';

import DocumentsFooter from '../documents-footer/DocumentsFooter';
import DocumentsItem from '../documents-item/DocumentsItem';
import DocumentsModal from '../documents-modal/DocumentsModal';
import { DocumentType } from './Document.types';
import m from './Documents.messages';

interface DocumentProps {
  setRedirectUrl: (v: string | null) => void;
}

const Documents: FC<DocumentProps> = ({ setRedirectUrl }) => {
  const { saveDocumentToStorage, getDocumentsFromStorage } = useDocuments();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [showSecciModal, setShowSecciModal] = useState(false);
  const [instanceId] = useApplicationFlowInstance();
  const { data } = useSelector((state) => state.application.flowInstance);
  const { environment } = useEnvironment();
  const { localeWithCountry } = useLocale();

  const closeAllModals = useCallback(() => {
    setShowSecciModal(false);
  }, []);

  const createDocument = useCallback(
    async (templateName: DocumentType) => {
      await dispatch(
        performApplicationFlowAction({
          instanceId,
          action: FlowActions.CREATE,
          data: {
            templateName,
            name: templateName === DocumentType.CREDIT_AGREEMENT ? 'agreement' : 'secci',
            type: templateName === DocumentType.CREDIT_AGREEMENT ? 'agreement' : 'secci',
          },
        })
      )
        .unwrap()
        .then((res) => {
          saveDocumentToStorage({
            type: templateName,
            id: res.responseData.id,
            isSigned: templateName === DocumentType.SECCI,
          });
        })
        .catch(() => {
          closeAllModals();
        });
    },
    [dispatch, instanceId, closeAllModals, saveDocumentToStorage]
  );

  const signDocument = useCallback(
    async (templateName: DocumentType) => {
      const { user } = getUserData();
      const { REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;
      const documentToSign =
        getDocumentsFromStorage()?.find((x) => x.type === templateName) ?? null;
      if (!documentToSign) {
        toast(formatMessage({ defaultMessage: 'Document ID is missing' }), { type: 'error' });
        return;
      }
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SIGN,
          instanceId,
          shouldRefreshToken: true,
          data: {
            documentId: documentToSign.id,
            data: {
              email: user?.email,
              name:
                user?.firstName && user?.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : (user.displayName ?? user.email),
              returnUrl: `${getPublicUrl(environment ?? 'development')}/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/documents?type=${templateName}`,
            },
          },
        })
      )
        .unwrap()
        .then((res) => {
          setRedirectUrl(res.responseData.description);
        });
    },
    [
      dispatch,
      instanceId,
      localeWithCountry,
      environment,
      formatMessage,
      getDocumentsFromStorage,
      setRedirectUrl,
    ]
  );

  const handleDocumentAccept = useCallback(
    async (templateName: DocumentType) => {
      switch (templateName) {
        // CREDIT AGREEMENT
        case DocumentType.CREDIT_AGREEMENT:
          try {
            await createDocument(templateName);
            await signDocument(templateName);
          } catch (e) {
            console.error(e);
          } finally {
            closeAllModals();
          }
          break;

        // SECCI
        case DocumentType.SECCI: {
          try {
            dispatch(setShowLoader(false));
            await createDocument(templateName);
          } finally {
            dispatch(setShowLoader(true));
            closeAllModals();
          }
          break;
        }
      }
    },
    [closeAllModals, dispatch, createDocument, signDocument]
  );

  const previewDocument = useCallback(
    async (templateName: DocumentType) => {
      dispatch(setShowLoader(false));
      await dispatch(
        performApplicationFlowAction({
          instanceId,
          action: FlowActions.PREVIEW,
          data: { templateName },
        })
      )
        .unwrap()
        .finally(() => {
          dispatch(setShowLoader(true));
        });
    },
    [dispatch, instanceId]
  );

  return (
    <>
      <ImageCard imageSrc={documentsImg} smHeightClassName="sm:h-[600px]">
        <div className="flex size-full flex-col justify-between gap-3 ">
          <div className="flex flex-col gap-5">
            <div className="font-heavy text-4xl font-black leading-10 text-black">
              {formatMessage(m.headTitle)}
            </div>
            <div className="text-base font-medium leading-normal text-black">
              {formatMessage(m.subtitle1)}
              <br />
              {formatMessage(m.subtitle2)}
            </div>
          </div>
          <DocumentsItem
            icon={<EyeIcon width="19" height="19" />}
            type={DocumentType.SECCI}
            headerText={formatMessage(m.secci)}
            subText={formatMessage(m.secciText)}
            onSignClick={async () => {
              setShowSecciModal(true);
              await previewDocument(DocumentType.SECCI);
            }}
          />
          <DocumentsItem
            icon={<PencilDrawlIcon />}
            type={DocumentType.CREDIT_AGREEMENT}
            headerText={formatMessage(m.loadAgreement)}
            subText={formatMessage(m.loadAgreementText)}
            onSignClick={async () => {
              await handleDocumentAccept(DocumentType.CREDIT_AGREEMENT);
            }}
          />
          <DocumentsFooter />
        </div>
      </ImageCard>

      {/* SECCI MODAL */}
      <DocumentsModal
        isOpen={showSecciModal}
        onBackClick={() => setShowSecciModal(false)}
        onAcceptClick={async () => {
          await handleDocumentAccept(DocumentType.SECCI);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: data?.responseData }} />
      </DocumentsModal>
    </>
  );
};

export default Documents;
