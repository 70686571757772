import { FC, ReactNode, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { FooterProps } from '@common/components/footer/Footer';
import { HeaderProps } from '@common/components/header/Header';
import MetaTags from '@common/components/meta-tags/MetaTags';
import { useComponent } from '@common/hooks/useComponent';
import { useSelector } from '@common/hooks/useSelector';
import MainLoader, { MainLoaderProps } from '@monefit-es/components/loaders/MainLoader';
import classNames from 'classnames';

export interface MainLayoutProps {
  children: ReactNode;
  userDisplayNameNode?: ReactNode;
  hideHeaderAndFooter?: boolean;
  showLoader?: boolean;
  loadingProp?: boolean;
  hideHeader?: boolean;
  type?: 'common' | 'dashboard';
  hideProgressBar?: boolean;
  childrenWrapperAlign?: 'center' | 'start';
  loaderType?: Pick<MainLoaderProps, 'type'>['type'];
  loaderComponent?: ReactNode;
  // TODO: Remove this prop when WaitingList page is replaced with dashboard page
  isWaitingList?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  userDisplayNameNode,
  hideHeaderAndFooter = false,
  showLoader = true,
}) => {
  const { loading: authenticationLoading } = useSelector((st) => st.authentication);
  const { formatMessage } = useIntl();
  const isLoading = useMemo(() => authenticationLoading, [authenticationLoading]);

  const [Header] = useComponent<HeaderProps>('header/Header');
  const [Footer] = useComponent<FooterProps>('footer/Footer');

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title="Monefit Credit"
          description={formatMessage({
            defaultMessage: 'Interest-free creditline',
            description: 'Interest-free creditline',
          })}
        />
      </HelmetProvider>
      <div
        className={classNames(
          'flex h-screen flex-col gap-6 bg-stone-50 p-2 md:p-12',
          hideHeaderAndFooter ? 'justify-center' : 'justify-between'
        )}
      >
        {!hideHeaderAndFooter && <Header userDisplayNameNode={userDisplayNameNode} />}
        {isLoading && showLoader ? (
          <MainLoader />
        ) : (
          <div className="flex w-full items-center justify-center">{children}</div>
        )}
        {!hideHeaderAndFooter && <Footer />}
      </div>
    </>
  );
};

export default MainLayout;
