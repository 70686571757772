import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, PlanFetch } from '@common/services/application';
import { getUserDisplayName } from '@common/utils';

import PlanSelectCard from '../plan-select-card/PlanSelectCard';
import m from './PlanSelect.messages';

const PlanSelect = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { data } = useSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const userName = getUserDisplayName();

  const creditTexts = useMemo(
    () => [formatMessage(m.creditText1), formatMessage(m.creditText2)],
    [formatMessage]
  );

  const subscriptionTexts = useMemo(
    () => [
      formatMessage(m.subscriptionText1, {
        percentage: data?.responseData?.interestPercentage
          ? (data?.responseData?.interestPercentage * 100).toFixed(2)
          : null,
      }),
      formatMessage(m.subscriptionText2),
      formatMessage(m.subscriptionText3),
    ],
    [formatMessage]
  );

  const handleCreditApply = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        data: { subscriptionId: null },
      })
    ).unwrap();
  }, [dispatch, instanceId]);

  const handleSubscriptionApply = useCallback(async () => {
    const typedData = data?.responseData as PlanFetch;
    const subscriptionId = typedData?.data?.[0]?.id;
    if (!subscriptionId) {
      throw new TypeError('No subscription id');
    }
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        data: { subscriptionId },
      })
    ).unwrap();
  }, [dispatch, data?.responseData, instanceId]);

  return (
    <div className="w-full max-w-[1100px] overflow-hidden">
      <div className="mb-6 flex w-full justify-center overflow-hidden">
        <div className="inline-flex flex-col items-center justify-start gap-4">
          <div className="flex items-center justify-center self-stretch overflow-hidden text-center text-lg  font-semibold text-black">
            <div className="break-all">{formatMessage(m.heading1, { name: userName })}</div>
          </div>
          {!!data?.responseData?.approvedLimit && (
            <div className="text-center text-5xl font-bold leading-[1.2] text-black">
              {formatMessage(m.heading2, {
                limit: formatNumber(data?.responseData.approvedLimit, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                }),
              })}
            </div>
          )}
          <div className="text-center text-base font-medium  text-black">
            {formatMessage(m.heading3)}
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col-reverse gap-6 lg:flex-row">
        <PlanSelectCard
          type="credit"
          texts={creditTexts}
          onApplyClick={handleCreditApply}
          planFetchData={data?.responseData}
        />
        <PlanSelectCard
          planFetchData={data?.responseData}
          type="subscription"
          texts={subscriptionTexts}
          onApplyClick={handleSubscriptionApply}
        />
      </div>
    </div>
  );
};

export default PlanSelect;
