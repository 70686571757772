import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ImageCard from '@common/components/image-card/ImageCard';
import { useSelector } from '@common/hooks';
import useIntercomChat from '@common/hooks/useIntercomChat';
import { selectFlowInstance } from '@common/redux/selectors';
import { FlowSteps } from '@common/services/application';
import declinedImg from '@src/assets/img/monefit-es/declined.png';

import m from './DeclinedCard.messages';

const DeclinedCard = () => {
  const { formatMessage } = useIntl();
  const flowInstanceData = useSelector(selectFlowInstance);
  const isDeclinedVerification = useMemo(
    () =>
      flowInstanceData.data?.responseData?.preDeclinedStepId === FlowSteps.IDENTIFY_VERIFICATION,
    [flowInstanceData.data?.responseData?.preDeclinedStepId]
  );

  const referenceNumber = useMemo(
    () =>
      flowInstanceData.data?.responseData?.referenceNumber || null,
    [flowInstanceData.data?.responseData?.referenceNumber]
  );

  const { openChat } = useIntercomChat();
  return (
    <ImageCard
      imageSrc={declinedImg}
      smHeightClassName={isDeclinedVerification ? 'sm:h-[600px]' : 'sm:h-[720px]'}
      lgHeightClassName={isDeclinedVerification ? 'lg:h-[500px]' : 'lg:h-[630px]'}
      maxWClassName={isDeclinedVerification ? 'max-w-[1000px]' : 'max-w-[1100px]'}
    >
      <div className="flex size-full flex-col justify-normal gap-5 sm:justify-between">
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="font-heavy text-[2.375rem] leading-[3rem] tracking-[-0.02375rem] text-black">{formatMessage(m.headerMainText)}</div>
          {isDeclinedVerification && <div>{formatMessage(m.failedVerificationText)}</div>}
        </div>
        {!isDeclinedVerification && (
          <div className="flex flex-col items-center justify-center gap-2.5">
            <div className="text-base leading-normal text-black">
              {formatMessage(m.headerDescription)}
            </div>

            <ul className="list-inside list-disc text-base sm:list-outside">
              <li>{formatMessage(m.declineReason1)}</li>
              <li>{formatMessage(m.declineReason2)}</li>
              <li>{formatMessage(m.declineReason3)}</li>
              <li>{formatMessage(m.declineReason4)}</li>
              <li>{formatMessage(m.declineReason5)}</li>
              <li>{formatMessage(m.declineReason6)}</li>
            </ul>
          </div>
        )}
        <div className="flex flex-col gap-5">
          <div className=" text-base font-medium text-black">{formatMessage(m.getInTouchText)}</div>
          {referenceNumber && (
            <div className=" text-base font-medium leading-6 text-black">
              {formatMessage(m.referenceNo)} <span className='font-bold'> { referenceNumber } </span>
            </div>
          )}
          <Button
            extraClassNames="mt-5 sm:mt-0"
            type="button"
            onClick={() => openChat()}
            fullWidth
            text={formatMessage(m.chatWithUs)}
          />
        </div>
      </div>
    </ImageCard>
  );
};

export default DeclinedCard;
