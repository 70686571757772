import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PRODUCTION_PUBLIC_URL, STAGING_PUBLIC_URL } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useLocale } from '@common/hooks';
import useEnvironment, { Environment } from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { selectFlowIntstanceData } from '@common/redux/selectors';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getUserData } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';

import ErrorBoundaryPage from '../error-boundary-page/ErrorBoundaryPage';

const IdentityVerificationPage = () => {
  const { localeWithCountry } = useLocale();
  const { environment } = useEnvironment();
  const data = useSelector(selectFlowIntstanceData);
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const [isErrored, setIsErrored] = useState(false);
  const {
    user: { id },
  } = getUserData();

  const [searchParams] = useSearchParams();
  const userIdParam = searchParams.get('userId');
  const [canStartPolling, setCanStartPolling] = useState(!!(userIdParam && userIdParam === id));

  const baseUrl = useMemo(
    () => (environment === Environment.PRODUCTION ? PRODUCTION_PUBLIC_URL : STAGING_PUBLIC_URL),
    [environment]
  );

  const submitVerification = useCallback(async () => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        shouldRefreshToken: true,
        data: {
          provider: 'veriff',
          redirectUrl: `${baseUrl}/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/identity-verification?userId=${id}`,
        },
      })
    )
      .unwrap()
      .then((r) => {
        window.location.href = r.responseData.url;
      })
      .catch(() => {
        setIsErrored(true);
      });
  }, [dispatch, instanceId, baseUrl, localeWithCountry, id]);

  const checkVerification = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CHECK,
      })
    )
      .unwrap()
      .then((r) => {
        if (r?.responseData?.url) {
          window.location.href = r.responseData.url;
        }
      })
      .catch(() => {
        setIsErrored(true);
      });
  }, [dispatch, instanceId]);

  const checkVerificationUsingFetch = useCallback(async () => {
    if (canStartPolling) {
      return;
    }
    await dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }))
      .unwrap()
      .then(async (r) => {
        if (!r.responseData.isIdentityVerificationCompleted) {
          await submitVerification();
        } else {
          setCanStartPolling(true);
        }
      })
      .catch(() => {
        setIsErrored(true);
      });
  }, [dispatch, instanceId, submitVerification, canStartPolling]);

  const handleFlowStart = useCallback(async () => {
    // Do nothing if can already start polling
    if (canStartPolling) {
      return;
    }
    // Flow not resumed, user landed here for the first time, submit verification
    if (!data?.isResumed) {
      await submitVerification();
      // Flow is resumed, check using fetch first
    } else {
      await checkVerificationUsingFetch();
    }
  }, [checkVerificationUsingFetch, submitVerification, canStartPolling, data]);

  useEffect(() => {
    handleFlowStart();
  }, []);

  useEffect(() => {
    if (!canStartPolling) {
      return;
    }
    const intervalId = setInterval(checkVerification, 5000);
    return () => clearInterval(intervalId);
  }, [checkVerification, canStartPolling]);

  return (
    <MainLayout hideHeaderAndFooter={!isErrored} hideProgressBar loaderType="image">
      {isErrored ? <ErrorBoundaryPage hasSignupLayout={false} /> : <MainLoader type="image" />}
    </MainLayout>
  );
};

export default IdentityVerificationPage;
