import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import { FlowActions } from '@common/services/application';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import loader from '@src/assets/img/monefit-es/bank_loader.webp';

import m from './OnboardingDecisionPage.messages';

const OnboardingDecisionPage = () => {
  const { formatMessage } = useIntl();

  useInitialFlowActionPerform({
    flowAction: FlowActions.SUBMIT,
  });

  const componentToShow = useMemo(
    () => (
      <div className="flex w-full items-center justify-center text-black">
        <LoadingCard text={formatMessage(m.loadingCardText)} imgSrcProp={loader} />
      </div>
    ),
    [formatMessage]
  );

  return (
    <MainLayout hideProgressBar hideHeaderAndFooter loaderComponent={componentToShow}>
      {componentToShow}
    </MainLayout>
  );
};

export default OnboardingDecisionPage;
