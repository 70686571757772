import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export interface SliderProps {
  name: string;
  max: number;
  min: number;
  step: number;
  color?: 'blue' | 'green';
  allowsDecimals?: boolean;
  externalHandler?: () => void;
  disabled?: boolean;
}

const Slider: FC<SliderProps> = ({
  name,
  max,
  min,
  step,
  color = 'blue',
  externalHandler,
  disabled = false,
  allowsDecimals = false,
}) => {
  const { register, trigger, setValue, getValues } = useFormContext();
  const { onChange, ...registration } = register(name);

  /*
    input type=[range] issue with min value and step
    https://stackoverflow.com/questions/68618465/unable-to-reach-input-type-range-to-max
  */
  const correctValue = useCallback(
    (nValue: number, nMin: number, nStep: number, nMax: number, allowsDecimals: boolean) => {
      const roundedStep = allowsDecimals ? nStep : Math.floor(nStep);
      if (nValue === nMin + Math.floor((nMax - nMin) / roundedStep) * roundedStep) {
        return nMax;
      } else {
        return nMin + Math.floor((nValue - nMin) / roundedStep) * roundedStep;
      }
    },
    []
  );

  const handleChange = useCallback(
    ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = getValues(name);
      const parsedValue = allowsDecimals ? parseFloat(newValue) : parseInt(newValue, 10);
      const corrected = correctValue(parsedValue, min, step, max, allowsDecimals);

      if (corrected < min) {
        setValue(name, currentValue);
        return;
      }

      setValue(name, corrected);
      trigger(name);
    },
    [setValue, trigger, step, correctValue, max, min, name, getValues, allowsDecimals]
  );

  useEffect(() => {
    externalHandler && externalHandler();
  }, [externalHandler]);

  useEffect(() => {
    const loadStyle = async () => {
      switch (color) {
        case 'blue':
          await import('@src/common/components/slider/slider-blue.css');
          break;
        case 'green':
          await import('@src/common/components/slider/slider-green.css');
          break;
        default:
          await import('@src/common/components/slider/slider-blue.css');
          break;
      }
    };

    loadStyle();
  }, [color]);

  return (
    <input
      type="range"
      step={allowsDecimals ? step : Math.floor(step)}
      max={max}
      min={min}
      disabled={disabled}
      {...registration}
      onChange={handleChange}
      id="slider"
    />
  );
};

export default Slider;
