import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { MainLayoutProps } from '@common/components/layout/Layout.Main';
import MetaTags from '@common/components/meta-tags/MetaTags';
import { useSelector } from '@common/hooks/useSelector';
import {
  selectAuthenticationLoading,
  selectFlowInstanceLoading,
  selectMeta,
} from '@common/redux/selectors';
import { selectMainLoading } from '@common/redux/selectors/banking';
import clsx from 'clsx';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import MainLoader from '../loaders/MainLoader';
import WaitingListFooter from '../waiting-list-footer/WaitingListFooter';

const CONTENT_SHOW_DELAY = 100; // Delay before showing content after loading finishes

const MainLayout: FC<MainLayoutProps> = ({
  children,
  userDisplayNameNode,
  hideHeaderAndFooter = false,
  hideHeader = false,
  showLoader: showLoaderProp = true,
  isWaitingList = false,
  hideProgressBar = false,
  type,
  loadingProp,
  childrenWrapperAlign = 'center',
  loaderType = 'dots',
  loaderComponent,
}) => {
  const authenticationLoading = useSelector(selectAuthenticationLoading);
  const applicationLoading = useSelector(selectFlowInstanceLoading);
  const bankingLoading = useSelector(selectMainLoading);
  const applicationMeta = useSelector(selectMeta);
  const { formatMessage } = useIntl();

  const [globalLoading, setGlobalLoading] = useState(true); // Initialize with true to show loader initially
  const [showContent, setShowContent] = useState(false); // Control when to show children

  const isLoading = useMemo(
    () => authenticationLoading || applicationLoading || bankingLoading || loadingProp,
    [authenticationLoading, applicationLoading, bankingLoading, loadingProp]
  );

  const shouldShowLoader = useMemo(() => {
    return isLoading && showLoaderProp && applicationMeta.showLoader;
  }, [isLoading, showLoaderProp, applicationMeta.showLoader]);

  useEffect(() => {
    let appearTimeout: ReturnType<typeof setTimeout> | null = null;
    let settleTimeout: ReturnType<typeof setTimeout> | null = null;

    if (shouldShowLoader) {
      if (!globalLoading) {
        appearTimeout = setTimeout(() => {
          setGlobalLoading(true);
          setShowContent(false);
        }, 0);
      }
    } else {
      if (globalLoading) {
        settleTimeout = setTimeout(() => {
          setGlobalLoading(false);
          setShowContent(true);
        }, 0);
      } else {
        settleTimeout = setTimeout(() => {
          setShowContent(true);
        }, CONTENT_SHOW_DELAY);
      }
    }

    return () => {
      if (appearTimeout) {
        clearTimeout(appearTimeout);
      }
      if (settleTimeout) {
        clearTimeout(settleTimeout);
      }
    };
  }, [shouldShowLoader, globalLoading]);

  const renderFooter = useCallback(() => {
    if (isWaitingList) {
      return <WaitingListFooter />;
    } else {
      return <Footer type={type} />;
    }
  }, [isWaitingList, type]);

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title="Monefit Credit"
          description={formatMessage({
            defaultMessage: 'Interest-free creditline',
            description: 'Interest-free creditline',
          })}
        />
      </HelmetProvider>

      <div
        className={clsx('flex min-h-svh flex-col gap-6 bg-stone-50 p-2 md:px-12 md:py-6', {
          'justify-center': hideHeaderAndFooter || globalLoading,
          'justify-between': !(hideHeaderAndFooter || globalLoading),
        })}
      >
        {globalLoading ? (
          (loaderComponent ?? <MainLoader type={loaderType} />)
        ) : showContent ? ( // Only render children if the loader is hidden and content is ready
          <>
            {!(hideHeaderAndFooter || hideHeader) && (
              <Header
                userDisplayNameNode={userDisplayNameNode}
                isWaitingList={isWaitingList}
                type={type}
                hideProgressBar={hideProgressBar}
                loadingProp={globalLoading}
              />
            )}

            <div
              className={clsx('flex size-full flex-1 grow flex-col items-center', {
                'justify-center': childrenWrapperAlign === 'center',
                'justify-start': childrenWrapperAlign === 'start',
              })}
            >
              {children}
            </div>

            {!hideHeaderAndFooter && renderFooter()}
          </>
        ) : null}
      </div>
    </>
  );
};

export default MainLayout;
