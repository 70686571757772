import { LocalStorageKeys } from '@common/constants';
import { store } from '@common/redux';
import { reset } from '@common/redux/slices/application';
import { AuthorizeResponse } from '@common/services/authentication';
import { jwtDecode } from 'jwt-decode';

export interface UserSessionStorageData extends AuthorizeResponse {
  agreementId?: string;
}

export const getUserData = () => {
  const userRaw = sessionStorage.getItem(LocalStorageKeys.USER);
  const userParsed: UserSessionStorageData = userRaw ? JSON.parse(userRaw) : null;
  return userParsed;
};

export const saveAgreementId = (id: string) => {
  const currentUserData = sessionStorage.getItem(LocalStorageKeys.USER);
  const parsed = currentUserData ? JSON.parse(currentUserData) : null;
  const newDataToSave: UserSessionStorageData = { ...parsed, agreementId: id };
  sessionStorage.setItem(LocalStorageKeys.USER, JSON.stringify(newDataToSave));
};

export const getDecodedAccessToken = () => {
  const userData = getUserData();
  const token = userData?.accessToken;
  return token ? jwtDecode(token) : null;
};

export const getRefreshToken = () => {
  const userData = getUserData();
  const token = userData?.refreshToken;
  return token ?? null;
};

export const getAccessToken = () => {
  const userData = getUserData();
  const token = userData?.accessToken;
  return token ?? null;
};

export const getSessionTimeLeft = () => {
  const token = getDecodedAccessToken();
  if (!token?.exp) {
    return null;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = token.exp;
  const secondsLeft = expirationTime - currentTime;

  return secondsLeft > 0 ? secondsLeft : null;
};

export const handleLogout = (localeWithCountry: string) => {
  store.dispatch(reset());
  sessionStorage.clear();
  window.location.replace(`/${localeWithCountry}/login`);
};

export const getIsLoggedIn = () => {
  const timeLeft = getSessionTimeLeft();
  return !!timeLeft;
};

export const getUserDisplayName = () => {
  const { user } = getUserData() ?? {};
  return user?.displayName ?? user?.firstName ?? user?.email;
};
