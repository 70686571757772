import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CurrencyInputField, {
  CurrencyInputProps as BaseCurrencyInputProps,
} from 'react-currency-input-field';
import { useController, useFormContext } from 'react-hook-form';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';
import InputLabel from '@common/components/input-label/InputLabel';
import clsx from 'clsx';

import { getClassNames } from './CurrencyInput.classes';

export interface CurrencyInputProps extends BaseCurrencyInputProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  limit?: number;
  hideError?: boolean;
  externalHandler?: () => void;
  customClassName?: string;
}

const CurrencyInput: FC<CurrencyInputProps> = ({
  name,
  label,
  description,
  limit,
  min,
  hideError = false,
  externalHandler,
  customClassName,
  allowDecimals = false,
  decimalsLimit = 0,
  suffix = ' €',
  ...props
}) => {
  const { formState, trigger } = useFormContext();
  const { isSubmitting, errors } = formState;

  const {
    field: { onChange, value, ...registration },
    fieldState: { isTouched, error },
  } = useController({
    name,
    rules: {},
  });

  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (isTouched) {
      trigger(name);
    }
  }, [isTouched, name, trigger]);

  const hasValue = props.value !== undefined || value !== undefined;

  useEffect(() => {
    externalHandler && externalHandler();
  }, [externalHandler]);

  const classes = useMemo(() => getClassNames(), []);

  const onValueChange = useCallback(
    (v?: string) => {
      if (!v) {
        onChange(null);
        return;
      }
      if (limit && Number(v) > limit) {
        onChange(limit);
        return;
      }

      onChange(v);
    },
    [limit, onChange]
  );

  return (
    <div className="relative w-full overflow-hidden">
      <CurrencyInputField
        autoComplete="off"
        disableGroupSeparators
        className={clsx(classes.base, customClassName, { 'pt-3': label })}
        suffix={suffix}
        disabled={isSubmitting}
        {...registration}
        {...props}
        allowNegativeValue={false}
        allowDecimals={allowDecimals}
        decimalsLimit={decimalsLimit}
        max={limit}
        value={value}
        onValueChange={onValueChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          trigger(name);
        }}
        placeholder={label ? '' : (props.placeholder ?? '')}
      />

      {!!label && <InputLabel name={name} text={label} shouldMinimize={isFocused || hasValue} />}
      {!!description && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {description}
        </div>
      )}
      {!!(error?.message || errors?.[name]?.message) && !hideError && (
        <div className="mt-2 text-xs font-semibold text-rose-400">
          {error?.message ?? errors?.[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
};

export default CurrencyInput;
