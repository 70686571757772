import { Payment, PaymentResponse } from '@common/types/payment';
import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { paymentServiceBaseUrl } = getCoreServiceBaseUrls();

export const paymentService = axios.create({
  baseURL: paymentServiceBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export const getPaymentByTransactionID = async (transactionId: string): Promise<null | Payment> => {
  const resp = await paymentService.get<PaymentResponse>(
    `/payment?filter[transactionId]=${transactionId}`
  );
  if (!resp?.data?.data?.length) {
    return null;
  }

  return resp.data.data[0];
};
