export enum AgreementStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  PENDING_WITHDRAWAL = 'PendingWithdrawal',
  CLOSED = 'Closed',
  TERMINATED = 'Terminated',
}

// TODO: add values for other originators
export enum AgreementOriginator {
  MONEFIT_ES = 'MONEFIT_ES',
}

export interface Agreement {
  id: string;
  identifier: string;
  contractNumber: string;
  originatorIdentifier: AgreementOriginator;
  typeId: string;
  type: string;
  statusId: string;
  status: AgreementStatus;
  userId: string;
  currency: string;
  config: unknown;
  createdAt: string;
  updatedAt: string;
  terminatedAt: string;
}

export interface AgreementsGetResponse {
  data: Agreement[];
  _links: {
    self: {
      href: string;
    };
    first: {
      href: string;
    };
    last: {
      href: string;
    };
  };
  _meta: {
    totalCount: number;
    pageCount: number;
    currentPage: number;
    perPage: number;
  };
}

// partial /agreement/{id}/info/balance response object, add more properties if needed
export interface AgreementBalance {
  unpaidInvoicedTotalBalance: number | null;
  invoiceDueDate: string | null;
  firstUnpaidInvoiceDueDate: string | null;
  nextInvoiceTargetDate: string;
  invoiceDueDateTerm: number;
  totalOutstandingAmountExcludingInterest: number;
  totalOutstandingAmount: number;
}

export interface AgreementSummaryBalance {
  principal: number;
  interest: number;
  lateInterest: number;
  monthFee: number;
  subscriptionFee: number;
  previouslyForgiven: number;
  total: {
    minPayment: number;
    maxPayment: number;
    balance: number;
    currentPayment: number;
    leftToPay: number;
  };
}

export interface AgreementSummaryInvoice {
  id: number;
  userId: string;
  agreementId: string;
  createdAt: string;
  creator: number;
  dueDate: string;
  targetDate: string;
  principalAmount: number;
  interestAmount: number;
  capitalDebtInterestAmount: number;
  admissionFeeAmount: number;
  fixedAdmissionFeeAmount: number;
  monthFeeAmount: number;
  otherFeeAmount: number;
  insuranceFeeAmount: number;
  resignFeeAmount: number;
  courtFeeAmount: number;
  collectionFeeAmount: number;
  collectionInterestAmount: number;
  subscriptionFeeAmount: number;
  totalAmount: number;
  documentId: string;
  periodStartDate: string;
  periodEndDate: string;
  documentLink: string;
}

export interface AgreementBalanceSummary {
  creditLimit: number;
  yearlyInterestPercentage: number;
  minimumTermPayment: number;
  dailyLateInterestPercentage: number;
  principalRepaymentPercentage: number;
  agreementAmount: number;
  agreementStatus: string;
  agreementScheduleType: string;
  agreementCurrency: string;
  agreementOriginatorIdentifier: string;
  agreementPaidOutAmount: number;
  agreementPaidOutDate: string;
  agreementTerminationThreshold: number;
  maximumAgreementWithdrawalInitiationDays: number;
  maximumAgreementWithdrawalRecoveryDays: number;
  receivedPaymentDetails: any | null;
  invoiceDueDateTerm: number;
  invoiceOnFirstOfMonth: boolean;
  prepaymentAllocationOnLastInvoiceDate: boolean;
  agreementIsFrozen: boolean;
  invoiceDueDate: string;
  prepaymentBalance: number;
  unpaidInvoicedTotalBalance: number;
  firstUnpaidInvoiceDueDate: string;
  nextInvoiceTargetDate: string;
  representativeAprc: number;
  invoice?: {
    lastInvoiceByDueDate: AgreementSummaryInvoice;
    lastInvoiceByTargetDate: AgreementSummaryInvoice;
  };
  balance: AgreementSummaryBalance;
}
